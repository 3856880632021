import React from 'react';
import { Stack, Typography } from '@mui/joy';

export const About = () => {
  return (
    <Stack direction="column" p={4} spacing={2}>
      <Typography level="h5" gutterBottom>
        About Me
      </Typography>
      <Typography>
        Incoming Software Engineer at Bloomberg LP in New York City, currently leveraging my
        expertise and passion for software engineering through a volunteer role as a Software
        Engineer Intern at a Fintech startup.
        <br />
        <br />
        My interest in software spans computer systems, compilers, programming languages, and
        web applications. In my free time, I love hiking, baking, writing poetry, listening to
        music, and playing a lot of Tetris… like… a lot.
        <br />
        <br />
        I am an active member of the Society of Hispanic Professional Engineers as a part of the
        Silicon Valley Professional Chapter.
      </Typography>
    </Stack>
  );
};
